<template>
	<div>
		<el-form :model="ruleForm" label-width="100rem" class="demo-ruleForm form-body" label-position="left">
			<h2>基础信息</h2>
			<el-row style="padding: 24rem 24rem 0">
				<el-col :span="8">
					<el-form-item label="房间名称">
						{{ ruleForm.room_name }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="房间编号">
						{{ ruleForm.room_no }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="所属校区">
						{{ ruleForm.school_name }}
					</el-form-item>
				</el-col>
			</el-row>

			<el-row style="padding: 0 24rem">
				<el-col :span="8">
					<el-form-item label="所属楼宇">
						{{ ruleForm.building_name }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="房间类型">
						{{ ruleForm.room_type_name }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="所属楼层">
						{{ ruleForm.floor }}
					</el-form-item>
				</el-col>
			</el-row>

			<el-row style="padding: 0 24rem">
				<el-col :span="8">
					<el-form-item label="容纳人数">
						{{ ruleForm.room_num }}
					</el-form-item>
				</el-col>
			</el-row>

			<el-row style="padding: 0 24rem;">
				<el-col :span="8">
					<el-form-item label="备注">
						{{ ruleForm.remarks }}
					</el-form-item>
				</el-col>
			</el-row>

			<h2>房间维护</h2>
			<el-row style="padding: 24rem">
				<el-col :span="8">
					<el-form-item label="房间状态">
						{{ ruleForm.room_status_name }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="管理员">
						{{ ruleForm.account_name }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="管理员电话">
						{{ ruleForm.phone }}
					</el-form-item>
				</el-col>
			</el-row>

			<h2>房间设施</h2>
			<el-row style="padding: 24rem 24rem 24rem 100rem">
				<el-col :span="2" style="opacity: 0;">0</el-col>
				<el-col :span="6">名称</el-col>
				<el-col :span="6">编号</el-col>
				<el-col :span="6">状态</el-col>
				<el-col :span="1" style="opacity: 0;">2</el-col>
			</el-row>
			<el-row style="padding: 0 24rem 24rem 100rem;display: flex;align-items: center" v-for="(item,index) in ruleForm.facilities">
				<el-col :span="2">设施{{ index + 1 }}</el-col>
				<el-col :span="6">
					{{ ruleForm.facilities[index].name }}
				</el-col>
				<el-col :span="6">
					{{ ruleForm.facilities[index].number }}
				</el-col>
				<el-col :span="6">
					{{ ruleForm.facilities[index].facilities_type_name }}
				</el-col>
				<el-col :span="1" style="font-size: 24rem;min-width: 62rem"></el-col>
			</el-row>
		</el-form>
		<div style="display: flex;justify-content: center;margin-top: 48rem;">
			<el-button @click="$router.back()" style="margin-right: 12rem">返回</el-button>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data() {
		return {
			ruleForm: {},
		};
	},
	created() {
		this.$_axios2.get('api/room/room/details?id=' + this.$route.query.id).then(res => {
			this.ruleForm = res.data.data
		});
	}
};
</script>

<style scoped lang="scss">

</style>
